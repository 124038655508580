import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Section from "@components/section"
import Container from "@components/container"
import Callout from "@components/callout"
import InlineVideoSection from "@components/inlineVideoSection"
import InlineVideo from "@components/inlineVideo"
import LearnMore from "@components/learnMore"
import PrimaryCta from "@components/PrimaryCta"
import Anchor from "@components/Anchor"
import { Heading2 } from "@styles/ui"

const pdfDownload = "https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"

const Dosing = ({ location, data }) => {
  return (
    <Layout
      location={location}
      pageBgColour="white"
      isiBgColour="white"
      footerBgColour="white"
    >
      <Seo
        title="XYWAV® Dosing for Idiopathic Hypersomnia | XYWAV for IH"
        description="XYWAV® (calcium, magnesium, potassium, and sodium oxybates) has dosing options with a once- or twice-nightly schedule for treating your Idiopathic Hypersomnia (IH). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <StaticHero
        eyebrow="Taking Xywav"
        imageData={data.heroImage}
        alt="man smiling"
        jumpLinks={[
          { text: "Dosing", link: "#dosing" },
          {
            text: "Download myWAV app",
            link: "#mywav",
          },
        ]}
        gradient="purple"
      >
        XYWAV offers twice- and once&#8209;nightly dosing options
      </StaticHero>

      <section tw="pt-9 pb-8 md:(pt-12 pb-10 lg:scroll-mt-[80px])" id="dosing">
        <Container>
          <h2 css={[Heading2, tw`mb-5 lg:mb-8`]}>
            XYWAV dosing can be individualized for adults with Idiopathic
            Hypersomnia (IH).
          </h2>
          <div>
            <p tw="mb-2.5 lg:mb-5">
              When you get your first shipment of XYWAV, be sure to read the{" "}
              <Anchor link={pdfDownload} newTab>
                Instructions for Use (IFU)
              </Anchor>
              . You&apos;ll also receive the XYWAV and XYREM REMS Patient Quick
              Start Guide along with your medication and detailed preparation
              and dosing instructions. Here&apos;s what you&apos;ll need to
              know:
            </p>
            <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm xl:(text-xl leading-[1.4])">
              <li>
                XYWAV is a liquid taken at bedtime. It&apos;s most often taken
                twice nightly (at bedtime and again 2 ½ to 4 hours later). It
                can also be taken once nightly (just at bedtime).
              </li>
              <li tw="mt-2.5 lg:mt-5">
                Your doctor may adjust your dose at first to find what works
                best for you. Be open and share with your doctor how you&apos;re
                feeling as your dose changes. Use the{" "}
                <Anchor link="/idiopathic-hypersomnia/talking-to-your-doctor/">
                  Talking With Your Doctor Quiz
                </Anchor>{" "}
                to track your symptoms.
              </li>
            </ul>
          </div>
        </Container>
      </section>

      {/* Video */}
      <InlineVideoSection
        video={
          <InlineVideo
            wistiaId="zcxb7qtoef"
            thumbnail={data.thumbImage}
            tall
            alt="Patient Jennie discusses taking XYWAV®"
          />
        }
        bgColour={tw`bg-lilac`}
      >
        <p tw="mb-4 px-6 md:(mb-6 px-0)">
          Jennie, a real patient and XYWAV IH Ambassador, shares what it&apos;s
          like taking twice-nightly XYWAV to help treat her Idiopathic
          Hypersomnia.{" "}
        </p>
        <div tw="flex justify-center md:justify-start">
          <Anchor
            link="/idiopathic-hypersomnia/resources/"
            hasArrow
            addedStyles={tw`text-sm font-bold text-left md:text-xl`}
          >
            Hear more from Jennie and
            <br />
            other XYWAV IH Ambassadors
          </Anchor>
        </div>
      </InlineVideoSection>

      <Callout heading={callouts[0].heading} cta={callouts[0].cta}>
        <p tw="lg:(font-ffcocon text-[30px] font-normal)">
          Yes! During the period of the clinical study that measured how well
          XYWAV worked, almost 8 out of 10 people (77%) were following a
          twice&#8209;nightly dosing&nbsp;schedule.
        </p>
      </Callout>

      <Section bgColour={tw`bg-seashell`}>
        <Container>
          <h2 css={[Heading2, tw`mb-6 text-center lg:(mb-12 text-left)`]}>
            Taking XYWAV at bedtime
          </h2>
          {bedtimeData.map((item, index) => {
            return (
              <div
                key={`bedtime-${index}`}
                css={[
                  tw`grid grid-cols-4 md:(grid-cols-12 mx-auto gap-x-8)`,
                  index < bedtimeData.length - 1 && tw`mb-6 md:mb-[60px]`,
                ]}
              >
                <img
                  src={item.image}
                  alt={item.imageAlt}
                  tw="col-span-full mx-auto mb-7 max-w-[253px] md:(col-span-5 mb-0 pl-0 pr-10 max-w-[320px]) xl:(pr-20 max-w-[458px])"
                />
                <div tw="col-span-full md:col-span-7">
                  <h3 tw="font-cocon font-bold leading-tight text-merlin text-4xl mb-4 lg:(text-5xl mb-5)">
                    {item.header}
                  </h3>
                  <div>{item.content}</div>
                </div>
              </div>
            )
          })}
        </Container>
      </Section>

      <Section>
        <Container>
          <h2 css={[Heading2, tw`mb-6`]}>
            It&apos;s important to
            <br tw="md:hidden" /> remember...
          </h2>
          <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
            <li tw="mb-4">
              Wait at least 2 hours after eating before taking XYWAV.
            </li>
            <li tw="mb-4">
              Prepared doses should be taken within 24 hours after mixing.
            </li>
            <li tw="mb-4">
              Store XYWAV in a safe place, away from children and pets.
            </li>
            <li>
              When starting a new treatment, changes can happen little by
              little. Tracking your progress can help you and your doctor
              discuss your results a bit more clearly.
            </li>
          </ul>

          <h3 tw="font-ffcocon text-2xl leading-[26px] font-bold text-studio text-center mt-[25px] md:(leading-[30px] text-4xl)">
            If you have questions, we&apos;ve got answers. Call JazzCares
            <sup>&reg;</sup> at{" "}
            <span tw="whitespace-nowrap">1-866-997-3688</span> Monday &ndash;
            Friday, 7am &ndash; 8pm, Central Time or visit{" "}
            <Anchor link="https://jazzcares.com" isExternal newTab>
              JazzCares.com
            </Anchor>
            .
          </h3>
        </Container>
      </Section>

      <Section bgColour={tw`bg-seashell`}>
        <Container>
          <div tw="grid grid-cols-4 md:(grid-cols-12 mx-auto gap-8)">
            <div tw="col-span-full md:(col-span-6 flex flex-col justify-center)">
              <h2 css={[Heading2, tw`mb-8 text-center lg:(text-left)`]}>
                XYWAV Medication Guide and Instructions for Use
              </h2>
              <p tw="mb-8 text-center md:text-left">
                Read the Instructions for Use and the Medication Guide for
                detailed instructions on preparing and taking XYWAV.
              </p>
              <div tw="flex justify-center md:block">
                <PrimaryCta url={pdfDownload} isExternal noInterstitial>
                  Download
                </PrimaryCta>
              </div>
            </div>
            <div tw="hidden md:(block col-start-8 col-span-5)">
              <a href={pdfDownload} target="_blank" rel="noopener noreferrer">
                <img
                  src={"/images/icons/xywav-medication-guide-download-icon.svg"}
                  alt="XYWAV® medication guide to download"
                />
              </a>
            </div>
          </div>
        </Container>
      </Section>

      <Callout id="mywav" heading={callouts[1].heading} cta={callouts[1].cta}>
        <p tw="mb-8">
          Connect to educational resources and support, when and where you need
          it! You&apos;ll find answers to common questions, personalized
          encouragement, useful tips, and an app to help you get started and
          manage your treatment.
        </p>
      </Callout>

      <LearnMore ctas={learnMoreCtas} ih colour="purple" />
    </Layout>
  )
}

export default Dosing

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "talk-to-your-doctor-ih/man-talking-to-doctor.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, formats: [AUTO, WEBP, AVIF])
      }
    }
    thumbImage: file(
      relativePath: { eq: "video-thumbnails/jennie-dosing-video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 511, placeholder: NONE)
      }
    }
  }
`
const callouts = [
  {
    heading: (
      <>
        Can someone with Idiopathic Hypersomnia really follow a{" "}
        <nobr>twice-nightly</nobr> dosing schedule?
      </>
    ),
    cta: {
      text: "See study results",
      url: "/idiopathic-hypersomnia/xywav-efficacy/",
    },
  },
  {
    heading: (
      <>
        Have you been prescribed XYWAV?
        <br /> Sign up for myWAV—your all-in-one digital tool.
      </>
    ),
    cta: {
      text: "Sign Up",
      url: "https://mywav.page.link/A7DtXwgxC2WHtmg59",
      external: true,
    },
  },
]
const learnMoreCtas = [
  {
    text: "Learn more about Patient Support Programs",
    url: "/idiopathic-hypersomnia/patient-support-programs/",
  },
  {
    text: "Discover additional patient resources",
    url: "/idiopathic-hypersomnia/resources/",
  },
]

const bedtimeData = [
  {
    image: "/images/icons/before-bedtime-dosing-icon.svg",
    imageAlt: "Before bedtime dosing icon",
    header: "Before bedtime",
    content: (
      <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm xl:(text-xl leading-[1.4])">
        <li tw="mb-4">
          Prepare your dose and put it in a safe and secure place. If
          you&apos;re taking <nobr>2 doses,</nobr> prepare both at the same
          time.
        </li>
        <li>
          Before you take XYWAV, each dose should be mixed with approximately{" "}
          <nobr>¼ cup</nobr> of water in the empty pharmacy containers provided
          to you.
        </li>
      </ul>
    ),
  },
  {
    image: "/images/icons/bedtime-dosing-icon.svg",
    imageAlt: "Bedtime dosing icon",
    header: "Bedtime",
    content: (
      <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
        <li>
          <p tw="mb-4">
            Take your first dose while in bed and lie down immediately.
          </p>
          <ul tw="[list-style: circle] [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
            <li tw="mb-4">
              XYWAV can cause sleep very quickly without making you feel drowsy.
            </li>
            <li>
              Some people fall asleep within 5 minutes, and most fall asleep
              within <nobr>15 minutes</nobr> (though the time it takes any
              individual to fall asleep may vary from night to night).
            </li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    image: "/images/icons/dosing-timeline-icon.svg",
    imageAlt: "Dosing timeline icon",
    header: "Taking XYWAV twice nightly? At bedtime and 2 ½ to 4 hours later",
    content: (
      <>
        <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
          <li tw="mb-5">
            <p tw="mb-4">
              If prescribed two nightly doses, you must wake up to take the
              second dose 2&nbsp;½ to 4 hours after your first, as instructed by
              your doctor.
            </p>
            <ul tw="[list-style: circle] [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
              <li>
                You may want to set an alarm clock to make sure you wake up to
                take the second dose.
              </li>
            </ul>
          </li>
        </ul>
        <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
          <li tw="mb-5">
            <p tw="mb-4">
              If you are prescribed XYWAV twice nightly and miss the second
              dose, skip it.
            </p>
            <ul tw="[list-style: circle] [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
              <li>Never take two doses of XYWAV at one time.</li>
            </ul>
          </li>
          <li>You should remain in bed after taking each dose of XYWAV.</li>
        </ul>
      </>
    ),
  },
]
